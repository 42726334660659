<template>
  <div class="wrapper">
    <story class="page__story" v-if="userData.type !== 'mother' && 0"></story>
    <main-layout-header :page-title="pageTitle"></main-layout-header>
    <div class="main-layout__body">
      <div class="page page--crypto" v-if="web3Exists">
        <div class="crypto__header">
          <div class="crypto__header-info">
            <div class="crypto__header-account">
              <div class="crypto__header-account-title">
                {{ $t("connected_account") }}:
              </div>
              <div
                class="crypto__header-account-select"
                v-if="this.selectedAccount"
              >
                <div class="crypto__header-account-selected">
                  {{ this.selectedAccount }}
                </div>
              </div>
              <div class="crypto__header-account-connect" v-else>
                <primary-button
                  :label="$t('connect_account')"
                  @click="connectAccounts"
                ></primary-button>
              </div>
            </div>
            <div class="crypto__header-balance">
              <div class="crypto__header-balance-title">
                {{ $t("balance") }} <b>(WELD)</b>:
              </div>
              <div class="crypto__header-balance-value">
                {{ toFixedDecimals(selectedAccountWeldBalance, 2) }}
              </div>
              <div
                class="crypto__header-balance-update"
                @click="updateWeldBalance"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.224 3.01983C12.3484 2.26896 11.2967 1.74263 10.16 1.49448C8.7038 1.17658 7.18416 1.33179 5.82231 1.93749C4.46045 2.5432 3.32749 3.56779 2.58835 4.86209C1.84922 6.15639 1.54251 7.65282 1.71288 9.13353C1.88325 10.6142 2.5218 12.0019 3.53556 13.0945C4.54933 14.1871 5.88538 14.9277 7.34921 15.2082C8.81304 15.4888 10.3282 15.2949 11.6742 14.6546C12.7948 14.1214 13.7495 13.302 14.4451 12.2849C14.6775 11.9451 14.5384 11.4903 14.1758 11.2953C13.8131 11.1003 13.365 11.24 13.1229 11.573C12.5845 12.3135 11.8675 12.9116 11.0337 13.3082C9.9744 13.8121 8.78193 13.9648 7.62989 13.744C6.47784 13.5232 5.42635 12.9404 4.6285 12.0805C3.83066 11.2206 3.32811 10.1285 3.19403 8.96312C3.05995 7.79778 3.30134 6.62007 3.88304 5.60144C4.46475 4.58282 5.3564 3.77645 6.4282 3.29976C7.49999 2.82306 8.69596 2.70091 9.84199 2.9511C10.6571 3.12905 11.4167 3.48875 12.0665 3.99829L10.6462 4.04257C10.2782 4.05405 9.9892 4.36168 10.0007 4.72969C10.0121 5.09771 10.3198 5.38673 10.6878 5.37526L13.6281 5.28358C14.1801 5.26637 14.6136 4.80492 14.5964 4.2529L14.5047 1.31264C14.4932 0.944633 14.1856 0.655603 13.8176 0.667078C13.4496 0.678553 13.1606 0.986187 13.172 1.3542L13.224 3.01983Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="weld-buy-form">
          <base-loader v-if="buyProcessActive"></base-loader>
          <div class="weld-buy-form__header">
            <div class="weld-buy-form__setters">
              <div
                class="weld-buy-form__setters-item"
                @click="setCoinAmountByPercents(25)"
              >
                25% {{ this.selectedCoin }}
              </div>
              <div
                class="weld-buy-form__setters-item"
                @click="setCoinAmountByPercents(50)"
              >
                50% {{ this.selectedCoin }}
              </div>
              <div
                class="weld-buy-form__setters-item"
                @click="setCoinAmountByPercents(75)"
              >
                75% {{ this.selectedCoin }}
              </div>
              <div
                class="weld-buy-form__setters-item"
                @click="setCoinAmountByPercents(100)"
              >
                100% {{ this.selectedCoin }}
              </div>
            </div>
            <div class="weld-buy-form__coin-balance" v-if="selectedCoin">
              {{ toFixedDecimals(this.coinBalance, 2) }} {{ this.selectedCoin }}
            </div>
          </div>
          <div class="weld-buy-form__input">
            <text-input
              :mask="amountWeldMask"
              v-model="weldAmount"
              :title="$t('weld_amount')"
              :v-errors="v$.weldAmount.$errors"
              class="weld-buy-form__input-el"
            ></text-input>
            <div class="weld-buy-form__input-coin">WELD</div>
          </div>
          <div class="weld-buy-form__input weld-buy-form__input--with-coin">
            <text-input
              :mask="amountCoinMask"
              v-model="coinAmount"
              :v-errors="v$.coinAmount.$errors"
              :title="$t('coin_amount')"
              class="weld-buy-form__input-el"
            ></text-input>
            <custom-select
              :values="formattedAvailableCoins"
              :show-selected-title="true"
              :empty-placeholder="$t('select_coin')"
              v-model="selectedCoin"
              class="weld-buy-form__input-coin-select"
            ></custom-select>
          </div>
          <div class="weld-buy-form__actions">
            <primary-button
              :label="$t('buy')"
              :disabled="buyProcessActive"
              @click="startBuyWeld"
            ></primary-button>
          </div>
        </div>
        <transaction-history-block
          :transactions="buyTransactions"
          class="buy-transactions"
        ></transaction-history-block>
        <base-loader v-if="initialDataLoading"></base-loader>
      </div>
      <div class="page page--crypto" v-else>
        <metamask-install-guide></metamask-install-guide>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Web3Service } from "@/services/crypto/web3/web3-service";
import PrimaryButton from "@/components/button/PrimaryButton";

import BaseLoader from "@/components/base/BaseLoader";
import CustomSelect from "@/components/input/CustomSelect";
import TextInput from "@/components/input/TextInput";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { PancakeSwapService } from "@/services/crypto/exchanges/pancake-swap-service";
import Big from "big.js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  CRYPTO_API_TRANSACTION_TYPES,
  CryptoApiHelper,
} from "@/office/crypto-api";
import TransactionHistoryBlock from "@/components/profile/Token/TransactionHistoryBlock";
import MetamaskInstallGuide from "@/components/profile/Token/MetamaskInstallGuide";
import mathMixin from "@/mixins/math-mixin";
import MainLayoutHeader from "@/components/layouts/MainLayout/MainLayoutHeader";
import Story from "@/components/profile/Tasks/Story";

const amountWeldMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  decimalLimit: 18,
});

const amountCoinMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  decimalLimit: 18,
});

export default {
  name: "BuyToken",
  components: {
    Story,
    MainLayoutHeader,
    MetamaskInstallGuide,
    TransactionHistoryBlock,
    TextInput,
    CustomSelect,
    BaseLoader,
    PrimaryButton,
  },
  mixins: [mathMixin],
  inject: ["showSuccessTip", "showErrorTip"],
  metaInfo: {
    title: "Buy Token",
  },
  provide() {
    return {
      updateWeldBalance: this.updateWeldBalance,
      validateNetworkAndChangeIfNotValid:
        this.validateNetworkAndChangeIfNotValid,
      connectAccounts: this.connectAccounts,
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      selectedAccount: null,
      selectedAccountWeldBalance: 0,
      isChainValid: false,
      initialDataLoading: true,
      buyProcessActive: false,
      ratesUSDT: {
        weld: 0,
        coin: 0,
      },
      amountWeldMask,
      amountCoinMask,
      weldAmount: 0,
      weldStakeAmount: 0,
      coinAmount: 0,
      coinBalance: 0,
      shouldUpdateWeldAmount: true,
      shouldUpdateCoinAmount: true,
      selectedCoin: "USDT",
      availableCoins: Web3Service.AVAILABLE_COINS,
      rateUpdateInterval: null,
      rateUpdateIntervalLimit: 60000,
      updateSelectedCoinBalanceInterval: null,
      updateSelectedCoinBalanceLimit: 10000,
      vuelidateExternalResults: {
        weldAmount: [],
        coinAmount: [],
        weldStakeAmount: [],
      },
      buyTransactions: [],
      web3Exists: true,
    };
  },
  validations() {
    return {
      weldAmount: {
        required: required,
      },
      coinAmount: {
        required: required,
      },
    };
  },
  computed: {
    pageTitle: function () {
      return this.$t(this.$route.meta.pageTitle) || "";
    },
    ...mapGetters(["web3Instance", "userData"]),
    ...mapState({
      accounts: (state) => state.crypto.accounts,
      chainId: (state) => state.crypto.currentChainId,
    }),
    formattedAvailableCoins() {
      let _aCoins = [];

      for (let _aCoin in this.availableCoins) {
        _aCoins.push({
          id: this.availableCoins[_aCoin],
          title: this.availableCoins[_aCoin],
        });
      }
      return _aCoins;
    },
  },
  async created() {
    this.initialDataLoading = true;

    if (!this.web3Instance) {
      await new Web3Service().loadWeb3ToStore();
    }

    if (!this.web3Instance) {
      this.web3Exists = false;
    } else {
      this.selectedAccount = this.accounts[0] || null;

      if (!this.selectedAccount) {
        // await this.connectAccounts();
      }

      if (this.selectedAccount) {
        await this.validateNetworkAndChangeIfNotValid();
        await this.updateWeldRate();
        await this.loadBuyTransactions();
      }

      this.rateUpdateInterval = setInterval(() => {
        this.updateWeldRate();
      }, this.rateUpdateIntervalLimit);

      if (this.selectedCoin) {
        await this.updateSelectedCoinBalance();

        this.updateSelectedCoinBalanceInterval = setInterval(() => {
          this.updateSelectedCoinBalance();
        }, this.updateSelectedCoinBalanceLimit);
      }
    }

    this.initialDataLoading = false;
  },
  beforeDestroy() {
    clearInterval(this.rateUpdateInterval);
    clearInterval(this.updateSelectedCoinBalanceInterval);
  },
  watch: {
    accounts: function () {
      if (this.accounts.length === 0) {
        this.selectedAccount = null;
        return;
      }

      if (!this.selectedAccount) {
        this.selectedAccount = this.accounts[0] || null;
        return;
      }

      if (
        !this.accounts.findIndex((acc) => acc === this.selectedAccount) > -1
      ) {
        this.selectedAccount = this.accounts[0];
      }
    },
    selectedAccount: async function () {
      this.initialDataLoading = true;

      await this.updateWeldBalance();
      await this.updateSelectedCoinBalance();
      await this.loadBuyTransactions();

      this.initialDataLoading = false;
    },
    chainId: async function () {
      this.initialDataLoading = true;
      await this.updateWeldBalance();
      await this.updateSelectedCoinBalance();
      await this.validateNetworkAndChangeIfNotValid();
      this.initialDataLoading = false;
    },
    weldAmount: function () {
      this.v$.$clearExternalResults();
      if (this.shouldUpdateCoinAmount) {
        this.updateSelectedCoinAmount();
      }
    },
    coinAmount: function () {
      if (this.shouldUpdateWeldAmount) {
        this.updateWeldAmount();
      }
    },
    selectedCoin: async function () {
      this.coinBalance = 0;

      await this.updateSelectedCoinAmount();

      clearInterval(this.updateSelectedCoinBalanceInterval);
      await this.updateSelectedCoinBalance();
      this.updateSelectedCoinBalanceInterval = setInterval(() => {
        this.updateSelectedCoinBalance();
      }, this.updateSelectedCoinBalanceLimit);
    },
  },
  methods: {
    async connectAccounts() {
      await new Web3Service()
        .requestAccounts()
        .catch((err) => console.log(err));
    },
    async updateWeldBalance() {
      if (this.selectedAccount) {
        this.selectedAccountWeldBalance =
          await new Web3Service().getWeldBalance(
            this.selectedAccount,
            this.chainId
          );
      } else {
        this.selectedAccountWeldBalance = 0;
      }
    },
    async updateSelectedCoinBalance() {
      this.coinBalance = await new Web3Service().getCoinBalance(
        this.selectedAccount,
        this.selectedCoin,
        this.chainId
      );
    },
    async validateNetworkAndChangeIfNotValid() {
      this.isChainValid =
        await new Web3Service().validateNetworkAndChangeIfNotValid(
          this.chainId
        );
    },
    async updateWeldRate() {
      this.ratesUSDT.weld = await new Web3Service().getWeldCoinRate(
        Web3Service.AVAILABLE_COINS.USDT,
        this.chainId
      );
    },
    async updateWeldAmount() {
      if (parseFloat(this.coinAmount) === parseFloat(0)) {
        this.setWeldAmount(0);
        return;
      }

      //NEW ALGORITHM
      let coinWeldRate = await new Web3Service().getWeldCoinRate(
        this.selectedCoin,
        this.chainId
      );

      this.setWeldAmount(
        Big(
          Big(this.coinAmount).div(coinWeldRate).toPrecision(18, 1)
        ).toPrecision()
      );
      //END NEW ALGORITHM

      // if (this.selectedCoin === this.availableCoins.USDT) {
      //   this.setWeldAmount(
      //     Big(
      //       Big(this.coinAmount)
      //         .div(Big(this.ratesUSDT.weld))
      //         .toPrecision(18, 1)
      //     ).toPrecision()
      //   );
      //   return;
      // }
      //
      // let coinRate = await new PancakeSwapService().getCoinRate(
      //   this.selectedCoin
      // );
      //
      // let coinAmountInUSDT = Big(
      //   Big(this.coinAmount).times(Big(coinRate)).toPrecision(18, 1)
      // ).toPrecision();
      //
      // this.setWeldAmount(
      //   Big(
      //     Big(coinAmountInUSDT).div(Big(this.ratesUSDT.weld).toPrecision(18, 1))
      //   ).toPrecision()
      // );
    },
    async updateSelectedCoinAmount() {
      if (parseFloat(this.weldAmount) === parseFloat(0)) {
        this.setCoinAmount(0);
        return;
      }

      //NEW ALGORITHM
      let coinWeldRate = await new Web3Service().getWeldCoinRate(
        this.selectedCoin,
        this.chainId
      );

      this.setCoinAmount(
        Big(
          Big(this.weldAmount).times(coinWeldRate).toPrecision(18, 1)
        ).toPrecision()
      );
      //END NEW ALGORITHM

      // if (this.selectedCoin === this.availableCoins.USDT) {
      //   this.setCoinAmount(
      //     Big(
      //       Big(this.weldAmount).times(this.ratesUSDT.weld).toPrecision(18, 1)
      //     ).toPrecision()
      //   );
      //   return;
      // }
      //
      // let coinRate = await new PancakeSwapService().getCoinRate(
      //   this.selectedCoin
      // );
      //
      // let weldInUSDTAmount = Big(
      //   Big(this.weldAmount).times(Big(this.ratesUSDT.weld)).toPrecision(18, 1)
      // ).toPrecision();
      //
      // this.setCoinAmount(
      //   Big(
      //     Big(weldInUSDTAmount).div(Big(coinRate)).toPrecision(18, 1)
      //   ).toPrecision()
      // );
    },
    setWeldAmount(newWeldAmount) {
      this.shouldUpdateCoinAmount = false;
      this.weldAmount = newWeldAmount;
      this.$nextTick(() => {
        this.shouldUpdateCoinAmount = true;
      });
    },
    setCoinAmount(newCoinAmount) {
      this.shouldUpdateWeldAmount = false;
      this.coinAmount = newCoinAmount;
      this.$nextTick(() => {
        this.shouldUpdateWeldAmount = true;
      });
    },
    async startBuyWeld() {
      this.buyProcessActive = true;
      try {
        let buyResult = await this.buyWeld();
        this.buyProcessActive = false;
        if (buyResult) {
          this.showSuccessTip(
            this.$t("tip.successfully_bought_weld", {
              weldAmount: this.weldAmount,
            })
          );
        }
      } catch (e) {
        this.buyProcessActive = false;
        this.showErrorTip(e.message, 5000);
      }
    },
    async buyWeld() {
      const validationResult = await this.v$.$validate();

      if (!validationResult) {
        return false;
      }

      if (!this.selectedAccount) {
        await this.connectAccounts();
      }

      let buyResult;

      if (this.selectedCoin === this.availableCoins.BNB) {
        buyResult = await new Web3Service().buyWeldWithCoin(
          this.coinAmount,
          this.selectedAccount,
          this.chainId,
          {
            amountUSDT: await this.getCoinAmountInUSDT(this.selectedCoin),
            rateUSDT: this.ratesUSDT.weld,
          }
        );
      } else {
        buyResult = await new Web3Service().buyWeldWithToken(
          this.coinAmount,
          this.selectedAccount,
          this.chainId,
          Web3Service.AVAILABLE_COINS[this.selectedCoin],
          {
            amountUSDT: await this.getCoinAmountInUSDT(this.selectedCoin),
            rateUSDT: this.ratesUSDT.weld,
          }
        );
      }

      await this.updateWeldBalance();

      return buyResult;
    },
    async getCoinAmountInUSDT(coin) {
      let coinRate = await PancakeSwapService.getCoinRate(coin);

      return Big(
        Big(this.coinAmount).times(Big(coinRate)).toPrecision(18, 1)
      ).toPrecision();
    },
    async setCoinAmountByPercents(percents) {
      this.coinAmount = Big(
        Big(this.coinBalance)
          .times(percents / 100)
          .toPrecision(18, 1)
      ).toPrecision();
    },
    async loadBuyTransactions() {
      if (!this.selectedAccount) {
        this.buyTransactions = [];
      }

      try {
        let _transactionsResponse =
          await CryptoApiHelper.getAccountTransactions(this.selectedAccount);

        this.buyTransactions = _transactionsResponse.data.transactions.filter(
          (_transaction) =>
            _transaction.type === CRYPTO_API_TRANSACTION_TYPES.BUY
        );
      } catch (e) {
        this.buyTransactions = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_variables.scss";
@import "~@/assets/scss/_mixins.scss";

.buy-weld-button {
  margin-bottom: 25px;
  margin-top: 25px;
}

.page {
  &--crypto {
    padding-top: 40px;
  }
}

.crypto {
  &__header {
    display: flex;
    align-items: flex-start;

    &-info {
      margin-left: auto;
    }

    &-account {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      text-align: right;

      &-title {
        margin-right: 10px;
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $gray1;
      }

      &-selected {
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $mainCold;
        font-weight: 600;
      }
    }

    &-balance {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-title {
        margin-right: 10px;
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $gray1;
      }

      &-value {
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        color: $mainCold;
        font-weight: 600;
      }

      &-update {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 4px;

        &:hover {
          svg {
            transform: rotate(360deg);
            opacity: 0.8;
          }
        }

        svg {
          transition: transform 0.2s linear, opacity 0.2s linear;
          width: 100%;
          height: 100%;

          path {
            fill: $mainCold;
          }
        }
      }
    }
  }
}

.weld-buy-form,
.weld-stake-form {
  position: relative;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0 0 62px rgba(183, 201, 211, 0.5);
  border-radius: 24px;

  padding: 20px 25px;

  &__input {
    display: flex;
    align-items: flex-end;
    margin-bottom: 55px;

    &-el {
      margin-right: 30px;
    }
  }

  &__header {
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__coin-balance {
    color: $gray1;
  }

  &__setters {
    display: flex;
    align-items: center;

    &-item {
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      color: $gray1;
      background-color: $grayLight;
      padding: 5px;
      border-radius: 6px;
      cursor: pointer;
      transition: opacity 0.2s linear;
      margin: 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .button-primary {
      width: auto;
    }
  }
}

.buy-transactions {
  margin-top: 65px;
}
</style>
